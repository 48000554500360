<mat-toolbar color="primary">
    <span>Événements</span>
    <button mat-icon-button mat-dialog-close aria-label="Close dialog">
        <mat-icon>close</mat-icon>
    </button>
</mat-toolbar>

<!-- Structure simplifiée qui s'adapte au contenu -->
<div class="event-content">
    <ul>
        <li *ngFor="let event of events" class="event-item">
            <div class="event-date">{{ event.date }}</div>
            <div class="event-description">{{ event.description }}</div>
        </li>
    </ul>
</div>