<mat-toolbar color="primary">
    <span>Réserver et payer</span>
    <button mat-icon-button mat-dialog-close aria-label="Close dialog">
        <mat-icon>close</mat-icon>
    </button>
</mat-toolbar>

<div class="payer-content">
    <section class="reservation">
        <h2>Réserver votre table</h2>
        <p>Pour réserver votre table chez nous, veuillez nous appeler :</p>
        <div class="contact-info">
            <mat-icon>phone</mat-icon>
            <span>+33 5 63 68 25 85</span>
        </div>
    </section>

    <section class="payment">
        <h2>Moyens de paiement acceptés</h2>
        <p>Nous acceptons les paiements par chèque et en espèces directement à notre établissement.</p>
        <ul>
            <li>
                <mat-icon>account_balance_wallet</mat-icon>
                <span>Chèque : À l'ordre de "Manjocarn"</span>
            </li>
            <li>
                <mat-icon>euro_symbol</mat-icon>
                <span>Espèces</span>
            </li>
        </ul>
    </section>
</div>