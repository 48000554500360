<mat-toolbar color="primary">
    <span>Manjocarn</span>
    <button mat-icon-button mat-dialog-close aria-label="Close dialog">
        <mat-icon>close</mat-icon>
    </button>
</mat-toolbar>

<div class="manjocarn-content">
    <h1 class="title">Bienvenue à la Guinguette de Manjocarn !</h1>
    <div class="description">
        <p>Lieu atypique en pleine nature qui possède une plage privée et un accès rivière. À Manjocarn on se tutoie et
            on passe un moment.</p>
        <p>On s'y arrête un temps, prendre une glace ou une bière... la guinguette est chaleureuse et on vous accueille
            avec le sourire 😊 Les enfants sont les bienvenus.</p>
    </div>
    <div class="philosophy">
        <h2>Notre philosophie</h2>
        <p>Nous n'apprécions pas l'hypocrisie des réseaux et la publicité: nous préférons fidéliser nos clients en se
            souvenant d'eux, de leur passage et de leurs habitudes..et favorisons le bouche à oreille qui génère une
            clientèle homogène, capable d'apprécier le site.</p>
        <p>Nous sommes conscients de ne pouvoir satisfaire tout le monde mais nous faisons le maximum pour contenter une
            majorité qui sait nous le rendre ❤ et nous les remercions de leur fidélité et gentillesse.</p>
    </div>
</div>