
export const environment = {
  production: true,
  instagramToken: 'IGAAJctFfzxTlBZAE9JWVNsTTZAaTHBOdG1GY3hNSGV6VWZANZAm5NS3l0ZAzg0M1Y5dE5oOHlfM01zSXl6TVRaSVdId0JvY0pFS3l2NndoVTEzQ0VTN2Y1eHZABVHBXSmppY01zajBqVi1NVW1qcTJWTnhRbEtPcHU2Q1RrSFRGMjlPYwZDZD',
  firebase: {
    apiKey: '',
    authDomain: '',
    projectId: '',
    storageBucket: '',
    messagingSenderId: '',
    appId: ''
  }
};
